<template>
  <!-- eslint-disable max-len  -->
  <v-content>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <AppTitle>
            <template slot="title">
              Pedidos <strong class="secondary--text">"{{ nameStatus }}"</strong>
            </template>
            <template v-if="dateStart === dateEnd" slot="subtitle">
              Para el dia
              <strong class="secondary--text">
                {{ dateStart | moment('dddd DD') }} de {{ dateStart | moment('MMMM - YYYY') }}
              </strong>
            </template>
            <template v-else slot="subtitle">
              Entre los dias
              <strong class="secondary--text">
                {{ dateStart | moment('dddd DD') }} de {{ dateStart | moment('MMMM - YYYY') }}
              </strong>
              y
              <strong class="secondary--text">
                {{ dateEnd | moment('dddd DD') }} de {{ dateEnd | moment('MMMM - YYYY') }}
              </strong>
            </template>
            <template slot="button">
              <v-tooltip bottom>
                <v-btn
                  slot="activator"
                  :disabled="loading"
                  :loading="loading"
                  flat
                  icon
                  class="mt-0 mx-1"
                  @click="changeShow"
                >
                  <v-icon>local_florist</v-icon>
                </v-btn>
                <span>Vista de diseño</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn
                  slot="activator"
                  :disabled="loading"
                  :loading="loading"
                  flat
                  icon
                  class="mt-0 mx-1"
                  @click="getOrders"
                >
                  <v-icon>autorenew</v-icon>
                </v-btn>
                <span>Actualizar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn
                  slot="activator"
                  :disabled="loading"
                  :loading="loading"
                  flat
                  icon
                  class="mt-0"
                  :to="{ name: 'printOrders', params: { date: general.date } }"
                >
                  <v-icon>print</v-icon>
                </v-btn>
                <span>Imprimir Pedidos</span>
              </v-tooltip>
            </template>
          </AppTitle>

          <v-layout>
            <v-flex xs4>
              <v-text-field v-model="dateStart" type="date" label="Fecha desde" placeholder=""></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field v-model="dateEnd" type="date" label="Fecha Hasta" placeholder=""></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-btn class="mt-2" color="success" block @click="getOrders">
                Buscar Pedidos
              </v-btn>
            </v-flex>
          </v-layout>
          <template v-if="!diseno">
            <template v-if="$mq !== 'sm' && $mq !== 'xs'">
              <v-card>
                <AppTitleCard>
                  <template slot="title">
                    En total
                    <strong class="secondary--text"> {{ orders.length }}</strong>
                    pedidos
                  </template>
                  <template slot="button">
                    <v-text-field
                      v-model="inputSearch"
                      label="Buscar pedidos en listado"
                      solo-inverted
                      color="info"
                      flat
                      hide-details
                      append-icon="search"
                      @keyup.native="searchInputHandle"
                    ></v-text-field>
                  </template>
                </AppTitleCard>
                <v-divider></v-divider>
                <v-data-table
                  :headers="headers"
                  :items="orders"
                  class="elevation-0 order-table"
                  color="secondary"
                  :disable-initial-sort="true"
                  :pagination.sync="pagination"
                  :rows-per-page-items="rowsPerPage"
                  rows-per-page-text="Pedidos por pagina"
                  no-data-text="No hay pedidos para el dia y estado seleccionado"
                  :loading="loading"
                >
                  <template slot="items" slot-scope="props">
                    <tr>
                      <td>
                        <div>
                          <v-btn
                            color="secondary"
                            target="_blank"
                            depressed
                            :to="{
                              name: 'orderIndex',
                              params: { id: props.item.id }
                            }"
                            small
                          >
                            {{ props.item.id }}
                          </v-btn>
                          <template v-if="general.status === 4">
                            <v-btn small depressed @click="activatePhoto(props.item.photoLarge)">
                              Foto de entrega
                            </v-btn>
                          </template>
                        </div>
                      </td>
                      <td @click="goOrder('orderIndex', props.item.id)">
                        {{ props.item.client }}
                      </td>
                      <td @click="goOrder('orderIndex', props.item.id)">
                        {{ props.item.paymentMethod }}
                      </td>
                      <td class="text-xs-right" @click="goOrder('orderIndex', props.item.id)">
                        {{ props.item.total | toPrice }}
                      </td>
                      <td @click="goOrder('orderIndex', props.item.id)">
                        {{ props.item.state }}
                      </td>
                      <td class="text-xs-left" @click="goOrder('orderIndex', props.item.id)">
                        {{ props.item.schedule }}
                      </td>
                      <td>
                        <!--
                              <template v-if="props.item.store_id === 0">
                                <v-btn color="primary">
                                  Asignar
                                </v-btn>
                              </template>
                              <template v-if="props.item.store_id === user.storeID">
                                Asignado
                              </template>
                            -->
                        <v-btn
                          icon
                          :color="status(props.item.document.active)"
                          @click="goOrder('orderHome', props.item.id)"
                        >
                          <v-icon small>
                            file_copy
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          :color="status(props.item.print.active)"
                          @click="goOrder('orderHome', props.item.id)"
                        >
                          <v-icon small>
                            print
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          :color="status(props.item.delivery.active)"
                          @click="goOrder('orderLocation', props.item.id)"
                        >
                          <v-icon small>
                            local_shipping
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          :color="status(props.item.design.active)"
                          @click="goOrder('orderDesign', props.item.id)"
                        >
                          <v-icon small>
                            local_florist
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          :color="status(props.item.location.active)"
                          @click="goOrder('orderLocation', props.item.id)"
                        >
                          <v-icon small>
                            location_on
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                  <template slot="pageText" slot-scope="props">
                    Pagina {{ props.pageStart }} de {{ props.itemsLength }}
                  </template>
                </v-data-table>
              </v-card>
            </template>
            <template v-else>
              <v-data-iterator
                :items="orders"
                :rows-per-page-items="rowsPerPage"
                :pagination.sync="pagination"
                content-tag="v-layout"
                row
                wrap
              >
                <v-flex slot="item" slot-scope="props" xs12 sm4 md4 lg3>
                  <v-card @click="goOrder('orderIndex', props.item.id)">
                    <v-card-title
                      ><h4 class="title font-weight-regular">
                        Pedido <strong>{{ props.item.id }}</strong>
                      </h4></v-card-title
                    >
                    <v-divider></v-divider>
                    <v-list dense>
                      <v-list-tile>
                        <v-list-tile-content>Cliente:</v-list-tile-content>
                        <v-list-tile-content class="align-end text-xs-right">{{
                          props.item.client
                        }}</v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile>
                        <v-list-tile-content>Forma de pago:</v-list-tile-content>
                        <v-list-tile-content class="align-end text-xs-right">{{
                          props.item.paymentMethod
                        }}</v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile>
                        <v-list-tile-content>Total:</v-list-tile-content>
                        <v-list-tile-content class="align-end">
                          {{ props.item.total | toPrice }}
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile>
                        <v-list-tile-content>Comuna:</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{ props.item.state }}</v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile>
                        <v-list-tile-content class="align-start">{{ props.item.schedule }}</v-list-tile-content>
                      </v-list-tile>
                      <v-divider></v-divider>
                      <v-list-tile class="px-0">
                        <v-btn
                          icon
                          small
                          class="mr-3 ml-0"
                          :color="status(props.item.document.active)"
                          @click="goOrder('orderHome', props.item.id)"
                        >
                          <v-icon small>
                            file_copy
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          small
                          class="mr-3 ml-0"
                          :color="status(props.item.print.active)"
                          @click="goOrder('orderHome', props.item.id)"
                        >
                          <v-icon small>
                            print
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          small
                          class="mr-3 ml-0"
                          :color="status(props.item.delivery.active)"
                          @click="goOrder('orderLocation', props.item.id)"
                        >
                          <v-icon small>
                            local_shipping
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          small
                          class="mr-3 ml-0"
                          :color="status(props.item.design.active)"
                          @click="goOrder('orderDesign', props.item.id)"
                        >
                          <v-icon small>
                            local_florist
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          small
                          class="mr-3 ml-0"
                          :color="status(props.item.location.active)"
                          @click="goOrder('orderLocation', props.item.id)"
                        >
                          <v-icon small>
                            location_on
                          </v-icon>
                        </v-btn>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-data-iterator>
            </template>
          </template>
          <template v-else>
            <v-layout wrap>
              <v-flex xs4>
                <v-card
                  class="mb-1"
                  hover
                  @click="
                    () => {
                      showElaborar = true
                      showPorElaborar = false
                    }
                  "
                >
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs5 text-xs-center>
                        <v-icon class="icon-xtra-large"> done </v-icon>
                      </v-flex>
                      <v-flex xs7 text-xs-right>
                        <h2 class="display-3" style="font-weight: 300;">
                          {{ orders.length }}
                        </h2>
                        <h3 class="subheading">Total de pedidos</h3>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs4>
                <v-card
                  class="mb-1"
                  hover
                  @click="
                    () => {
                      showElaborar = true
                      showPorElaborar = false
                    }
                  "
                >
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs5 text-xs-center>
                        <v-icon class="icon-xtra-large"> done </v-icon>
                      </v-flex>
                      <v-flex xs7 text-xs-right>
                        <h2 class="display-3" style="font-weight: 300;">
                          {{ porElaborar.length }}
                        </h2>
                        <h3 class="subheading">Por elaborar</h3>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs4>
                <v-card
                  class="mb-1"
                  hover
                  @click="
                    () => {
                      showElaborar = false
                      showPorElaborar = true
                    }
                  "
                >
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs5 text-xs-center>
                        <v-icon class="icon-xtra-large"> done_all </v-icon>
                      </v-flex>
                      <v-flex xs7 text-xs-right>
                        <h2 class="display-3" style="font-weight: 300;">
                          {{ elaborados.length }}
                        </h2>
                        <h3 class="subheading">Elaborados</h3>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex v-if="porElaborar.length > 0 && showElaborar" xs12>
                <v-layout wrap>
                  <v-flex xs12 sm6>
                    <h2 class="title">Productos por elaborar</h2>
                  </v-flex>
                  <v-flex v-if="user.id === 6 || user.id === 12" xs12 sm3>
                    <v-switch v-model="agrupar" class="ma-0" color="secondary" label="Agrupar"></v-switch>
                  </v-flex>
                  <v-flex v-if="user.id === 6 || user.id === 12" xs12 sm3>
                    <v-switch
                      v-model="composicion"
                      class="ma-0"
                      color="secondary"
                      label="Activar composicion"
                    ></v-switch>
                  </v-flex>
                  <v-flex v-if="agrupar" xs12>
                    <v-card>
                      <v-data-table
                        :headers="[
                          { text: 'Codigo', value: 'id' },
                          { text: 'Nombre', value: 'name' },
                          { text: 'Cantidad', value: 'quantity' }
                        ]"
                        :items="groupProduct"
                        class="elevation-0"
                        disable-initial-sort
                        hide-actions
                      >
                        <template v-slot:items="props">
                          <td class="text-xs-left">P{{ props.item.id }}</td>
                          <td class="text-xs-left" v-html="props.item.name"></td>
                          <td class="text-xs-left">{{ props.item.total }}</td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-flex>
                  <v-flex v-if="composicion" xs12>
                    <v-card>
                      <v-card-title>
                        <h2>Cantidad de flores Flowerbox</h2>
                      </v-card-title>
                      <v-data-table
                        :headers="[
                          { text: 'Color', value: 'type' },
                          { text: 'Cantidad', align: 'right', value: 'quantity' }
                        ]"
                        :items="onlyCajas"
                        class="elevation-0 mb-4"
                        disable-initial-sort
                        hide-actions
                      >
                        <template v-slot:items="props">
                          <td class="text-xs-left">{{ props.item.name }}</td>
                          <td class="text-xs-right">{{ props.item.quantity }}</td>
                        </template>
                      </v-data-table>
                    </v-card>
                    <v-card>
                      <v-data-table
                        :headers="[
                          { text: 'Tipo', value: 'type' },
                          { text: 'Nombre', value: 'name' },
                          { text: 'Cantidad por elaborar', align: 'right', value: 'quantity' },
                          { text: 'Cantidad elaborado', align: 'right', value: 'quantity' },
                          { text: 'Cantidad total', align: 'right', value: 'quantity' }
                        ]"
                        :items="insumos"
                        class="elevation-0"
                        disable-initial-sort
                        hide-actions
                      >
                        <template v-slot:items="props">
                          <td class="text-xs-left">{{ props.item.type }}</td>
                          <td>{{ props.item.name }}</td>
                          <td class="text-xs-right">
                            {{ props.item.quantity }}
                          </td>
                          <td class="text-xs-right">
                            {{ checkElaborados(props.item.id) }}
                          </td>
                          <td class="text-xs-right">
                            {{ props.item.quantity + checkElaborados(props.item.id) }}
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-flex>
                  <template v-if="!agrupar">
                    <v-flex v-for="(product, key) in porElaborar" :key="key" d-flex xs6 md4 lg3 xl2>
                      <v-card class="mb-1">
                        <router-link
                          target="_blank"
                          :to="{
                            name: 'orderIndex',
                            params: { id: product.orderId }
                          }"
                        >
                          <v-img :src="product.img" :lazy-src="`https://picsum.photos/10/6`" class="lighten-2" />
                        </router-link>
                        <v-card-text>
                          <v-layout wrap row>
                            <v-flex xs12>
                              <div>
                                <div class="subheading font-weight-regular" v-html="product.name"></div>
                                <div class="caption mb-2">(P{{ product.productID }})</div>
                                <div>
                                  <template v-if="product.disenadorID > 0">
                                    <v-chip color="accent" text-color="white" label small class="mx-0">
                                      {{ product.disenadorName }}
                                    </v-chip>
                                    <v-chip color="warning" text-color="black">
                                      <v-avatar>
                                        <v-icon>timelapse</v-icon>
                                      </v-avatar>
                                      <TimeGo :number="product.seconds"></TimeGo>
                                    </v-chip>
                                  </template>
                                  <template v-if="composicion && product.receta.length > 0">
                                    <v-list
                                      style="margin-left: -15px !important;margin-right: -15px !important;"
                                      class="pa-0 ma-0"
                                      two-line
                                    >
                                      <v-list-tile v-for="(receta, i) in product.receta" :key="i" class="pa-0">
                                        <v-list-tile-content>
                                          <v-list-tile-title
                                            style="white-space: normal;"
                                            v-html="receta.name"
                                          ></v-list-tile-title>
                                          <v-list-tile-sub-title v-html="receta.type"></v-list-tile-sub-title>
                                        </v-list-tile-content>
                                        <v-list-tile-action>
                                          {{ receta.quantity }}
                                        </v-list-tile-action>
                                      </v-list-tile>
                                    </v-list>
                                  </template>
                                  <template v-if="composicion && product.receta.length === 0">
                                    <router-link
                                      target="_blank"
                                      :to="{
                                        name: 'productReceta',
                                        params: { id: product.productID }
                                      }"
                                    >
                                      <v-chip color="error" text-color="white" label small class="mx-0">
                                        No tiene receta
                                      </v-chip>
                                    </router-link>
                                  </template>
                                </div>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </template>
                </v-layout>
              </v-flex>
              <v-flex v-if="elaborados.length > 0 && showPorElaborar" xs12>
                <v-layout wrap>
                  <v-flex xs12>
                    <h2 class="title">Pedidos elaborados</h2>
                  </v-flex>
                  <v-flex v-for="(product, key) in elaborados" :key="key" d-flex xs6 lg2 xl2>
                    <v-card class="mb-1">
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'orderIndex',
                          params: { id: product.orderId }
                        }"
                      >
                        <v-img :src="product.img" :lazy-src="`https://picsum.photos/10/6`" class="lighten-2" />
                      </router-link>
                      <v-card-text>
                        <v-layout wrap row>
                          <v-flex xs12>
                            <div>
                              <div class="subheading font-weight-regular" v-html="product.name"></div>
                              <div class="caption mb-2">(P{{ product.productID }})</div>
                              <div>
                                <template v-if="product.disenadorID > 0">
                                  <v-chip color="accent" text-color="white" label small class="mx-0">
                                    {{ product.disenadorName }}
                                  </v-chip>
                                  <v-chip color="success" text-color="white">
                                    <v-avatar>
                                      <v-icon>timelapse</v-icon>
                                    </v-avatar>
                                    <span
                                      >{{ $moment.duration(product.seconds, 'seconds').minutes() }}:{{
                                        $moment.duration(product.seconds, 'seconds').seconds()
                                      }}
                                      min</span
                                    >
                                  </v-chip>
                                </template>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog ref="dialog" v-model="modalPhotoLarge" lazy full-width width="800px">
      <v-card v-if="photoLargeActive">
        <img :src="photoLargeActive" style="max-width:800px;outline:0;display: block;" alt="" />
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import { GET_ORDER_N } from '../config'
import AppTitle from './useful/title.vue'
import AppTitleCard from './useful/titleCard.vue'
import TimeGo from './useful/timeGo.vue'

export default {
  name: 'Home',
  components: { AppTitle, AppTitleCard, TimeGo },
  props: ['diseno'],
  metaInfo: {
    title: 'Pedidos'
  },
  computed: {
    groupProduct() {
      const product = []
      const group = this._.groupBy(this.onlyProducts, 'productID')
      this._.forEach(group, (i, e) => {
        product.push({
          id: e,
          name: i[0].name,
          total: i.length
        })
      })
      return this._.orderBy(product, ['total'], ['desc'])
    },
    user() {
      return this.$store.getters.getUser
    },
    insumos() {
      const products = this.porElaborar
      const recetas = []
      this._.forEach(products, product => {
        this._.forEach(product.receta, receta => {
          recetas.push(receta)
        })
      })
      const recetasReturn = []
      const recetasFilter = this._.groupBy(recetas, 'insumoID')
      this._.forEach(recetasFilter, value => {
        recetasReturn.push({
          id: value[0].insumoID,
          name: value[0].name,
          type: value[0].type,
          quantity: this._.sumBy(value, 'quantity')
        })
      })
      return this._.orderBy(recetasReturn, ['quantity'], ['desc'])
    },
    insumosElaborados() {
      const products = this.elaborados
      const recetas = []
      this._.forEach(products, product => {
        this._.forEach(product.receta, receta => {
          recetas.push(receta)
        })
      })
      const recetasReturn = []
      const recetasFilter = this._.groupBy(recetas, 'insumoID')
      this._.forEach(recetasFilter, value => {
        recetasReturn.push({
          id: value[0].insumoID,
          name: value[0].name,
          type: value[0].type,
          quantity: this._.sumBy(value, 'quantity')
        })
      })
      return this._.orderBy(recetasReturn, ['quantity'], ['desc'])
    },
    nameStatus() {
      const states = this.$store.getters.getStates
      const general = this.$store.getters.getGeneral
      const statusActive = this._.filter(states, ['id', general.status])
      if (statusActive[0] !== undefined) {
        return statusActive[0].name
      }
      return ''
    },
    general() {
      return this.$store.getters.getGeneral
    },
    onlyProducts() {
      const products = []
      this._.forEach(this.orders, order => {
        this._.forEach(order.products, product => {
          product.orderId = order.id
          products.push(product)
        })
      })
      return products
    },
    porElaborar() {
      return this._.filter(this.onlyProducts, ['status', 0])
    },
    onlyCajas() {
      const products = this._.filter(this.onlyProducts, [
        'name',
        'Rosas en Caja - FlowerBox Rosalinda: Regala Emociones con Rosas a Domicilio'
      ])
      const flowers = []
      const flowersFinish = []
      products.forEach(e => {
        let co = e.comentario.replaceAll('<br />', '\n')
        co = co.split('\n')
        co.forEach(c => {
          flowers.push(c)
        })
      })
      const flowerF = flowers.filter(el => {
        return el != null && el != ''
      })
      flowerF.forEach(flo => {
        const flof = flo.split(' x ')
        const finish = {
          quantity: parseInt(flof[0], 10),
          name: flof[1]
        }
        flowersFinish.push(finish)
      })
      var result = []
      flowersFinish.reduce(function(res, value) {
        if (!res[value.name]) {
          res[value.name] = { name: value.name, quantity: 0 }
          result.push(res[value.name])
        }
        res[value.name].quantity += value.quantity
        return res
      }, {})
      return result
    },
    elaborados() {
      return this._.filter(this.onlyProducts, ['status', 1])
    }
  },
  watch: {
    composicion() {
      this.getOrders()
    },
    'general.date': function() {
      this.getOrders()
    },
    'general.status': function() {
      this.getOrders()
    }
  },
  methods: {
    async getOrders() {
      this.loading = true
      try {
        const res = await this.$http.get(
          `${GET_ORDER_N}?${this.$qs.stringify({
            filter: {
              composicion: this.composicion,
              date: this.general.date,
              dateStart: this.dateStart,
              dateEnd: this.dateEnd,
              status: this.general.status,
              schedule: null
            }
          })}`
        )
        console.log(res)
        this.orders = res.data
        this.ordersAll = res.data
        this.loading = false
        this.dialogFilter = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async downloadItem(o) {
      const response = await this.$http.get(o.photoDownload, {
        responseType: 'blob'
      })
      const blob = new Blob([response.data], { type: response.data.type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = o.id
      link.click()
      URL.revokeObjectURL(link.href)
    },
    activatePhoto(e) {
      this.photoLargeActive = null
      setTimeout(() => {
        this.photoLargeActive = e
        this.modalPhotoLarge = true
      }, 100)
    },
    changeShow() {
      if (!this.diseno) {
        this.$router.push({
          name: 'pedidosDiseno',
          params: { diseno: 'vista-diseno' }
        })
      } else {
        this.$router.push({ name: 'pedidos' })
      }
    },
    status(e) {
      if (e) {
        return 'success'
      }
      return 'error'
    },
    goOrder(nameRoute, id) {
      this.$router.push({ name: nameRoute, params: { id } })
    },
    checkElaborados(id) {
      const { insumosElaborados } = this
      const item = this._.head(this._.filter(insumosElaborados, ['id', id]))
      return item ? item.quantity : 0
    },
    searchInputHandle: _.debounce(function debounce() {
      const search = this.inputSearch

      const result = this._.filter(this.ordersAll, item => {
        let res = this._.includes(this._.lowerCase(item.client), this._.lowerCase(search))
        if (!res) {
          res = this._.includes(this._.lowerCase(item.paymentMethod), this._.lowerCase(search))
          if (!res) {
            res = this._.includes(this._.lowerCase(item.schedule), this._.lowerCase(search))
            if (!res) {
              res = this._.includes(this._.lowerCase(item.id), this._.lowerCase(search))
              if (!res) {
                res = this._.includes(this._.lowerCase(item.total), this._.lowerCase(search))
              }
            }
          }
        }
        return res
      })

      if (result.length > 0) {
        this.orders = result
      } else {
        this.orders = []
      }
    }, 500)
  },
  data() {
    return {
      stats: {},
      items: [],
      agrupar: false,
      dialogFilter: false,
      showElaborar: true,
      showPorElaborar: false,
      menuDate: false,
      loading: false,
      composicion: false,
      modalPhotoLarge: false,
      photoLargeActive: null,
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      orders: [],
      ordersAll: [],
      headers: [
        { text: 'Pedido', align: 'left', sortable: false },
        { text: 'Cliente', align: 'left', sortable: false },
        { text: 'Forma de pago', align: 'left', sortable: false },
        { text: 'Total', align: 'right', sortable: false },
        { text: 'Comuna', sortable: false },
        { text: 'Horario de entrega', sortable: false },
        { text: '', sortable: false }
      ],
      inputSearch: null,
      dateStart: null,
      dateEnd: null
    }
  },
  mounted() {
    this.dateStart = this.general.date
    this.dateEnd = this.general.date
    this.getOrders()
  }
}
</script>

<style>
.order-table tbody {
  height: 100px;
}
.icon-xtra-large {
  font-size: 80px !important;
}
</style>
